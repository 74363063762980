module services {
    export module tariff {
        export class wcoTradeToolsService implements interfaces.tariff.IWCOTradeToolsService {
            static $inject = ["$resource", "ENV","$http", "$timeout","generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            GetWCOClassificationOpinions(isSection:number,section:string, code:string, year:number): ng.resource.IResourceClass<interfaces.tariff.IWCONotes> {
                return this.$resource<interfaces.tariff.IWCONotes>(this.ENV.DSP_URL + "WCOTradeTools/GetWCOClassificationOpinions", {
                    isSection: isSection,
                    section: section,
                    code: code,
                    year: year
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            GetWCOExplanatoryNotes(isSection:number,section:string, code:string, year:number): ng.resource.IResourceClass<interfaces.tariff.IWCONotes> {
                return this.$resource<interfaces.tariff.IWCONotes>(this.ENV.DSP_URL + "WCOTradeTools/GetWCOExplanatoryNotes", {
                    isSection:isSection,
                    section: section,
                    code: code,
                    year: year
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }          
            

            GetWCOLegalNotes(isSection:number,section:string, code:string, year:number): ng.resource.IResourceClass<interfaces.tariff.IWCONotes> {
                return this.$resource<interfaces.tariff.IWCONotes>(this.ENV.DSP_URL + "WCOTradeTools/GetWCOLegalNotes", {
                    isSection:isSection,
                    section: section,
                    code: code,
                    year: year
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }    

            VerifyWCOTradeToolsEnabled(): ng.resource.IResourceClass<interfaces.tariff.VerifyTradeTools> {
                return this.$resource<interfaces.tariff.VerifyTradeTools>(this.ENV.DSP_URL + "WCOTradeTools/VerifyWCOTradeToolsEnabled", {
       
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }                                 

        }
    }
    angular.module("app").service("wcoTradeToolsService", services.tariff.wcoTradeToolsService);
}